var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "prices", staticStyle: { "margin-bottom": "20px" } },
    [
      _c("h3", { staticClass: "product-page__info-price clr-blue-7 t-h3" }, [
        _vm._v("Цена:"),
        _vm.prices.length == 0
          ? _c(
              "a",
              {
                staticClass: "link-arrow clr-blue-10 t-tdn trs js-modal-btn",
                attrs: { href: "#" },
                on: { click: _vm.showModal },
              },
              [
                _vm._v(" По запросу"),
                _c("span", { staticClass: "media-obj link-arrow__icon trs" }, [
                  _c(
                    "svg",
                    { attrs: { xmlns: "http://www.w3.org/2000/svg" } },
                    [
                      _c("use", {
                        attrs: {
                          href: "/local/templates/renaissance/layout/dist/assets/img/sprite.svg#i-chevron",
                        },
                      }),
                    ]
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm.prices.length == 1
          ? _c("span", { staticClass: "link-arrow clr-blue-10 t-tdn trs" }, [
              _vm._v(" " + _vm._s(_vm.prices[0].price)),
            ])
          : _vm._e(),
      ]),
      _vm.prices.length > 1
        ? _c("table", { staticClass: "product-page__info-table js-table" }, [
            _vm._m(0),
            _c(
              "tbody",
              { staticClass: "clr-blue-7 t-md" },
              _vm._l(_vm.prices, function (price) {
                return _c(
                  "tr",
                  {
                    staticClass: "js-table-row",
                    class: { active: price.active },
                  },
                  [
                    _c("td", [_vm._v(_vm._s(price.periodText))]),
                    _c("td", [_vm._v(_vm._s(price.price))]),
                  ]
                )
              }),
              0
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticClass: "bg-blue-10 clr-white t-h3" }, [
      _c("tr", [_c("th", [_vm._v("Количество")]), _c("th", [_vm._v("Цена")])]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }