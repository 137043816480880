<template lang="pug">
include ../../utils/picture.pug

.prices(style='margin-bottom: 20px')
  h3.product-page__info-price.clr-blue-7.t-h3(
  ) Цена:
    a.link-arrow.clr-blue-10.t-tdn.trs.js-modal-btn(
    href=`#`
    v-on:click="showModal"
    v-if="prices.length == 0"
    )  По запросу
      +picture(`link-arrow__icon trs`, {
        svgName: `i-chevron`,
        basePathParam: '/local/templates/renaissance/layout/dist/',
        isSVG: true,
      })
    span.link-arrow.clr-blue-10.t-tdn.trs(
      v-if="prices.length == 1"
    )  {{prices[0].price}}

  table.product-page__info-table.js-table(
    v-if="prices.length > 1"
  )
    thead.bg-blue-10.clr-white.t-h3
      tr
        th Количество
        th Цена
    tbody.clr-blue-7.t-md
      tr.js-table-row(
         v-bind:class="{active: price.active}"
         v-for="price in prices"
      )
        td {{price.periodText}}
        td {{price.price}}
</template>


<script>
  let prices = {
    data () {
      return {
        count: 1
      }
    },
    props: {
      productPrices: {
      },
    },
    computed: {
      prices() {
        if (typeof this.productPrices !== 'object') {
          return []
        }
        if (typeof this.productPrices === 'object' && this.productPrices[0].PRICE == 0) {
          return []
        }

        return this.productPrices.map(function(price){
          let periodText
          if(price.QUANTITY_FROM && price.QUANTITY_TO) {
            periodText = price.QUANTITY_FROM + ' - ' + price.QUANTITY_TO
          }
          if(price.QUANTITY_FROM && !price.QUANTITY_TO) {
            periodText = '>=' + price.QUANTITY_FROM
          }
          if(!price.QUANTITY_FROM && price.QUANTITY_TO) {
            periodText = '1' + ' - ' +price.QUANTITY_TO
          }

          return {
            'price': price.PRICE + ' ' + price.currency,
            'rawprice': parseInt(price.PRICE),
            'periodText': periodText,
            'active': price.active,
          }
        });
      }
    },
    methods: {
      showModal() {
        let event = new CustomEvent('showModal', {
          detail: {
            formId: 9
          }
        })
        document.dispatchEvent(event)
      }
    }
  }

  export default prices
</script>
