var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "controls" },
    [
      _c("productPrices", { attrs: { productPrices: _vm.productPrices } }),
      _c(
        "div",
        { staticClass: "product-page__info-btns" },
        [
          _c("counter", {
            attrs: { value: _vm.count },
            on: { increment: _vm.increment, decrement: _vm.decrement },
          }),
          !_vm.inBasket
            ? _c(
                "button",
                {
                  staticClass:
                    "product-page__info-btn btn bg-blue-10 clr-white",
                  attrs: { type: "button" },
                  on: { click: _vm.addToBasket },
                },
                [_vm._v("Добавить к заказу")]
              )
            : _vm._e(),
          _vm.inBasket
            ? _c(
                "a",
                {
                  staticClass:
                    "product-page__info-btn btn bg-blue-10 clr-white",
                  attrs: { href: "/cart/", type: "button" },
                },
                [_vm._v("Перейти в корзину")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "product-page__info-btns",
          staticStyle: { "margin-top": "15px" },
        },
        [
          !_vm.inCompare
            ? _c(
                "button",
                {
                  staticClass:
                    "product-page__info-btn btn bg-blue-10 clr-white",
                  attrs: { type: "button" },
                  on: { click: _vm.addToCompare },
                },
                [_vm._v("Добавить к сравнению")]
              )
            : _vm._e(),
          _vm.inCompare
            ? _c(
                "a",
                {
                  staticClass:
                    "product-page__info-btn btn bg-blue-10 clr-white",
                  attrs: { href: "/compare/", type: "button" },
                },
                [_vm._v("В сравнении")]
              )
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "product-page__info-btns",
          staticStyle: { "margin-top": "15px" },
        },
        [
          _c(
            "button",
            {
              staticClass:
                "product-page__info-btn btn btn--outline bg-white clr-blue-10 js-modal-btn",
              attrs: { type: "button", "data-form-id": "10" },
              on: {
                click: function ($event) {
                  return _vm.getCommercial()
                },
              },
            },
            [_vm._v("Получить коммерческое предложение")]
          ),
          _c(
            "button",
            {
              staticClass:
                "product-page__info-btn btn btn--outline bg-white clr-blue-10 js-modal-btn",
              attrs: { type: "button", "data-form-id": "8" },
              on: {
                click: function ($event) {
                  return _vm.getQuestion()
                },
              },
            },
            [_vm._v("Задать вопрос")]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }