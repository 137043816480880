<template lang="pug">
.product-page__info-amount.clr-blue-10.js-product-amount.t-xxs
  button(type=`button` v-on:click="$emit('decrement')") Удалить 1 товар
  input(
    type=`number`
    min=`1`,
    max=`1000`
    size=`3`
    step=`1`
    :value="value"
    v-on="listeners"
  )
  button(type=`button` v-on:click="$emit('increment')") Добавить 1 товар
</template>

<script>
  const counter = {
    props: {
      value: {
        type: Number,
        default: ''
      }
    },
    computed: {
      listeners () {
        return {
          // Pass all component listeners directly to input
          ...this.$listeners,
          // Override input listener to work with v-model
          input: event => this.$emit('input', event.target)
        }
      }
    }
  }

  export default counter
</script>
