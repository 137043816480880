document.querySelectorAll("[data-scroll-to]").forEach((button) => {
    const yOffset = window.innerWidth > 990 ? 80 : 0;
    button.addEventListener("click", () => {
      document.querySelectorAll("[data-scroll-target]").forEach((target) => {
        if(button.dataset.scrollTo == target.dataset.scrollTarget){
          const y = target.getBoundingClientRect().top + window.pageYOffset - yOffset;
          window.scrollTo({top: y, behavior: 'smooth'});
        }
      })
    })
})