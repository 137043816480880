import {disableBodyScroll, clearAllBodyScrollLocks} from 'body-scroll-lock';

const TRANSITION_DURATION = 500;

export default class ImageFullscreen {
  constructor(el) {
    this.el = el;

    this.modal = null;
    this.closeBtn = null;
    this.img = null;
    this.imgCoords = null;

    this.closeBtnClickHandler = this.closeBtnClickHandler.bind(this);
    this.documentKeyupHandler = this.documentKeyupHandler.bind(this);
    this.destroy = this.destroy.bind(this);

    if (this.el) {
      this.init();
    }
  }

  createModal() {
    this.modal = document.createElement(`DIV`);
    this.modal.className = `trs`;
    this.modal.style.cssText = `
      overflow: auto;
      position: fixed;
      z-index: 12;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.7);
      opacity: 0;
      transition: linear ${TRANSITION_DURATION}ms;
    `;

    document.body.appendChild(this.modal);

    setTimeout(() => {
      this.modal.style.opacity = 1;
    });
  }

  createCloseBtn() {
    this.closeBtn = document.createElement(`BUTTON`);
    this.closeBtn.className = `trs`;
    this.closeBtn.style.cssText = `
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 0;
      font-size: 0;
      color: white;
      border: 0;
      background-color: transparent;
    `;
    this.closeBtn.innerHTML = `
      Закрыть окно просмотра изображения
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
        <path d="M1 1L8 8M15 15L8 8M8 8L15 1L1 15" stroke="currentColor" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    `;

    this.closeBtn.firstElementChild.style.cssText = `
      position: absolute;
      top: 4rem;
      right: 4rem;
      width: 32px;
      height: 32px;
    `;

    this.modal.appendChild(this.closeBtn);
  }

  createNav() {
    this.prevBtn = document.createElement(`BUTTON`);
    this.prevBtn.className = `project-img-btn project-img-btn--prev`;
    this.nextBtn = document.createElement(`BUTTON`);
    this.nextBtn.className = `project-img-btn project-img-btn--next`;

    this.modal.appendChild(this.prevBtn);
    this.modal.appendChild(this.nextBtn);
  }

  createImg(img) {
    if (img) {
      this.imgCoords = img.getBoundingClientRect();
      const {top, left, width, height} = this.imgCoords;
      let {naturalWidth, naturalHeight} = img;
      const ratio = naturalWidth / naturalHeight;

      if (naturalWidth > window.innerWidth * 0.8) {
        naturalWidth = window.innerWidth * 0.8;
        naturalHeight = naturalWidth / ratio;
      }

      if (naturalHeight > window.innerHeight * 0.8) {
        naturalHeight = window.innerHeight * 0.8;
        naturalWidth = naturalHeight * ratio;
      }

      this.img = document.createElement(`IMG`);

      this.img.className = `trs`;
      this.img.src = img.currentSrc;
      this.img.alt = img.alt;
      this.img.style.cssText = `
        position: fixed;
        z-index: 13;
        top: ${top}px;
        left: ${left}px;
        width: ${width}px;
        height: ${height}px;
        object-fit: contain;
        transition: linear ${TRANSITION_DURATION}ms;
      `;

      document.body.appendChild(this.img);

      setTimeout(() => {
        this.img.style.top = `50%`;
        this.img.style.left = `50%`;
        this.img.style.width = `${naturalWidth}px`;
        this.img.style.height = `${naturalHeight}px`;
        this.img.style.marginTop = `${-naturalHeight / 2}px`;
        this.img.style.marginLeft = `${-naturalWidth / 2}px`;
      });
    }
  }

  changeImg(img) {
    if (img) {
      this.img.remove();

      this.imgCoords = img.getBoundingClientRect();
      const {top, left, width, height} = this.imgCoords;
      let {naturalWidth, naturalHeight} = img;
      const ratio = naturalWidth / naturalHeight;

      if (naturalWidth > window.innerWidth * 0.8) {
        naturalWidth = window.innerWidth * 0.8;
        naturalHeight = naturalWidth / ratio;
      }

      if (naturalHeight > window.innerHeight * 0.8) {
        naturalHeight = window.innerHeight * 0.8;
        naturalWidth = naturalHeight * ratio;
      }

      this.img = document.createElement(`IMG`);

      this.img.src = img.currentSrc;
      this.img.alt = img.alt;
      this.img.style.cssText = `
        position: fixed;
        z-index: 13;
        top: ${top}px;
        left: ${left}px;
        width: ${width}px;
        height: ${height}px;
        object-fit: contain;
      `;

      document.body.appendChild(this.img);

      setTimeout(() => {
        this.img.style.top = `50%`;
        this.img.style.left = `50%`;
        this.img.style.width = `${naturalWidth}px`;
        this.img.style.height = `${naturalHeight}px`;
        this.img.style.marginTop = `${-naturalHeight / 2}px`;
        this.img.style.marginLeft = `${-naturalWidth / 2}px`;
      });
    }
  }

  close() {
    const {top, left, width, height} = this.imgCoords;

    this.img.style.top = `${top}px`;
    this.img.style.left = `${left}px`;
    this.img.style.width = `${width}px`;
    this.img.style.height = `${height}px`;
    this.img.style.marginTop = 0;
    this.img.style.marginLeft = 0;

    this.modal.style.opacity = 0;

    setTimeout(this.destroy, TRANSITION_DURATION);
  }

  closeBtnClickHandler() {
    this.close();
  }

  prevBtnClickHandler() {
    const wrapper = this.el.closest('.swiper-wrapper');

    const slide = this.el.closest('[data-index]');
    const index = parseInt(slide.dataset.index);
    if (index === 0) return false;

    const newIndex = index - 1;
    const newEl = wrapper.querySelector(`[data-index="${newIndex}"]`);
    this.el = newEl;
    this.changeImg(this.el.querySelector('img'));
  }

  nextBtnClickHandler() {
    const wrapper = this.el.closest('.swiper-wrapper');
    const len = wrapper.children.length;

    const slide = this.el.closest('[data-index]');
    const index = parseInt(slide.dataset.index);
    if (index === len - 1) return false;

    const newIndex = index + 1;
    const newEl = wrapper.querySelector(`[data-index="${newIndex}"]`) || wrapper.querySelector(`[data-index="0"]`);
    this.el = newEl;
    this.changeImg(this.el.querySelector('img'));
  }

  documentKeyupHandler(e) {
    if (e.key === `Escape`) {
      this.close();
    }
  }

  bind() {
    this.prevBtn.addEventListener(`click`, this.prevBtnClickHandler.bind(this));
    this.nextBtn.addEventListener(`click`, this.nextBtnClickHandler.bind(this));

    this.closeBtn.addEventListener(`click`, this.closeBtnClickHandler);
    document.addEventListener(`keyup`, this.documentKeyupHandler);
  }

  unbind() {
    this.prevBtn.removeEventListener(`click`, this.prevBtnClickHandler);
    this.nextBtn.removeEventListener(`click`, this.nextBtnClickHandler);

    this.closeBtn.removeEventListener(`click`, this.closeBtnClickHandler);
    document.removeEventListener(`keyup`, this.documentKeyupHandler);
  }

  init() {
    this.createModal();
    this.createCloseBtn();
    this.createNav();
    this.createImg(this.el.querySelector(`img`));
    this.bind();

    disableBodyScroll(this.modal, {
      reserveScrollBarGap: true,
    });
  }

  destroy() {
    this.unbind();
    this.img.remove();
    this.modal.remove();
    clearAllBodyScrollLocks();
  }
}
