import axios from 'axios'

let url = '/bitrix/services/main/ajax.php'
let shop = {
  loadProductsInBasket: function(callback) {
    axios.get(url, {
      params: {
        action: 'its:shop.api.Main.getProductsInBasket',
      }
    })
    .then(callback)
  },
  getProductPrices: function(productId) {
    return axios.get(url, {
      params: {
        action: 'its:shop.api.Main.getProductPrices',
        productId: productId
      }
    })
  },
  getCompareIds: function(callback) {
    return axios.get(url, {
      params: {
        action: 'its:shop.api.Main.getCompareIds',
      }
    })
    .then(callback)
  },
  addToBasket: function(productId, quantity, callback) {
    axios({
      method: 'post',
      url: url,
      params: {
        productId: productId,
        quantity: quantity,
        action: 'its:shop.api.Main.addToBasket'
      }
    })
    .then(callback)
  },
  addToCompare: function(productId, callback) {
    return axios.get(url, {
      params: {
        action: 'its:shop.api.Main.addToCompare',
        productId: productId
      }
    })
    .then(callback)
  },
  getProductItems: function(filter) {
    return axios({
      method: 'post',
      url: url,
      data: filter,
      params: {
        action: 'its:shop.api.Main.getProductItems',
      }
    })
  },
  getProductInfo: function(productId) {
    return axios.get(url, {
      params: {
        action: 'its:shop.api.Main.getProductInfo',
        productId: productId
      }
    })
  }
}

export default shop
