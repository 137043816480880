import Inputmask from "inputmask";

const modal = document.querySelector('.js-landpage-modal')
if (modal) {
    document.addEventListener('click', e => {
        if (e.target.closest('.js-landpage-modal-open')) {
            e.preventDefault()
            openModal()
        }
        if (e.target.closest('.js-landpage-modal-close')) {
            closeModal()
        }
    })
    
    window.addEventListener('keydown', e => {        
        if (e.keyCode === 27) {
            closeModal()
        }
    });

    function openModal() {
        modal.classList.add('is-active')
        document.body.classList.add(`page--stop-scroll`)
    }

    function closeModal() {
        modal.classList.remove('is-active')
        document.body.classList.remove(`page--stop-scroll`)

        const successEl = document.querySelector('.js-landpage-modal-success.is-active')
        if (successEl) {
            successEl.classList.remove('is-active')
        }
    }

    // Tabs
    document.addEventListener('click', function(e) {
        const tab = e.target.closest('[data-tab-link]')
        if (!tab) return;
        const id = tab.dataset.tab
        const tabName = tab.dataset.tabLink
        const content = document.querySelector(`[data-tab-content='${tabName}'][data-tab='${id}']`)
        if (content) {
            if (document.querySelector(`[data-tab-content='${tabName}'].open`)) {
                document.querySelector(`[data-tab-content='${tabName}'].open`).classList.remove('open')
            }
            if (document.querySelector(`[data-tab-link='${tabName}'].active`)) {
                document.querySelector(`[data-tab-link='${tabName}'].active`).classList.remove('active')
            }
            tab.classList.add('active')
            content.classList.add('open')
        }
    })

    // Формы
    const forms = document.querySelectorAll('.js-landpage-modal-form')
    forms.forEach(form => {
        const formSubmit = form.querySelector('.js-landpage-modal-submit')
        let inputTel = form.querySelector('.js-landpage-tel')
        let inputFile = form.querySelector('.js-landpage-file')
        const inputFileText = form.querySelector('.js-landpage-file-name')
        const formFields = form.querySelectorAll('input:not([type="hidden"]), textarea')
        const successEl = form.querySelector('.js-landpage-modal-success')
        console.log(formFields)
    
        const phoneMask = '+7 (999) 999-99-99';
        const phoneInputMask = new Inputmask(phoneMask);
        phoneInputMask.mask(inputTel);

        inputFile.addEventListener("change", () => {
            inputFileText.innerHTML = inputFile.files[0] ? inputFile.files[0].name : 'Выберите файл';
        })
    
        formSubmit.addEventListener('click', function(e) {
            const elsWithErrors = form.querySelectorAll('.error');
            elsWithErrors.forEach(el => {
                el.classList.remove('error');
            });
    
            let valid = true;
    
            formFields.forEach(function(input) {
                if ((input.required && !input.value.trim().length) || (input.type === 'email' && !inputEmailValidate(input.value)) || (input.type === 'tel' && !inputPhoneValidate(input.value, phoneMask)) || (input.type === 'checkbox' && !input.checked)) {
                    valid = false
                    input.classList.add('error')
                    form.classList.add('error')
                }
            })
    
            if (!valid) {
                e.preventDefault()
            }
        });
    
        form.addEventListener('submit', function(e) {
            e.preventDefault();
    
            const formData = new FormData(form);
            console.log('success')
    
            postData(form.action, { body: formData }).then(function() {
                formFields.forEach(function(input) {
                    input.value = ''
                })
                inputFileText.innerHTML = 'Выберите файл'
                successEl.classList.add('is-active')
            });
        })
    
        function inputEmailValidate(value) {
            const emailRegex = /(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.))+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]/i;
            const result = value.match(emailRegex);
    
            if (result && result[0]) return true;
            return false;
        }
    
        function inputPhoneValidate(value, mask, canBeEmpty = false) {
            if (canBeEmpty && value === '') return true;
            return Inputmask.isValid(value, { mask: mask });
        }
    
        function postData(url, options) {
            const requestOptions = {
                method: 'POST',
                headers: options.headers,
                body: options.body,
            };
            return fetch(url, requestOptions).then(function(response) {
                // return response.json();
                if (response.ok) {
                    return response.json()
                }
            });
        }
    })
}