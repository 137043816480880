require('es6-promise').polyfill();
import Vue from "vue";
import Vuex from "vuex";
import axios from 'axios'
import VueAxios from 'vue-axios'
import shop from '~/js/modules/shop'

Vue.use(VueAxios, axios)
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    modal: {
      title: '',
      submitText: '',
      successText: '',
      fields: {}
    },
    plainform: {
      title: '',
      submitText: '',
      successText: '',
      fields: [],
      hiddenFields: []
    },
    location: {
      phone: '',
      phoneUrl: ''
    },
    compareIds: [
    ],
    basketCount: 0,
    compareCount: 0,
    productsInBasket: [
    ],
    productPrices: [
    ],
    productItems: [
    ],
    productItemsPage: 1,
    productInfo: []
  },
  getters: {
    getLocationPhone: state => () => state.location.phone,
    getLocationPhoneUrl: state => () => state.location.phoneUrl,
    getProductInBasket: state => curProductId => {
      return state.productsInBasket.filter(productId => parseInt(productId) === parseInt(curProductId))
    },
    getCompareIds: state => () => state.compareIds,
    getProductPrices: state => {
      return state.productPrices;
    }
  },
  mutations: {
    location_change(state, location) {
      const curLocation = window.locationList.filter(el => el.name === location);
      state.location.phone = curLocation[0].phone;
      state.location.phoneUrl = curLocation[0].phoneUrl;
    },
    basketCountInit(state, count) {
      state.basketCount = parseInt(count);
    },
    compareCount(state, count) {
      state.compareCount = parseInt(count);
    },
    compareIds(state, productIds) {
      state.compareIds = state.compareIds.concat(productIds)
    },
    modalShow(state, modal) {
      state.modal = modal;
    },
    plainFormShow(state, plainform) {
      state.plainform = plainform;
    },
    modalHide(state, modal) {
      state.modal.show = false;
    },
    changeField(state, field) {
      state.modal.fields.map(function(stateField) {
        if (stateField.id === field.id) {
          if (field.type === 'file') {
            stateField.value = field.files[0]
            stateField.fileSelect = true
            stateField.fileSelectName = typeof field.files[0] == 'object' ? field.files[0].name : ''
          } else {
            stateField.value = field.value
          }
        }
      })
    },
    changeFieldP(state, field) {
      state.plainform.fields.map(function(stateField) {
        if (stateField.id === field.id) {
          if (field.type === 'file') {
            stateField.value = field.files[0]
          } else {
            stateField.value = field.value
          }
        }
      })
    },
    validateFields(state, fields) {
      fields.map(function(field) {
        state.modal.fields.map(function(stateField) {
          if (stateField.id === field.id) {
            stateField.error = field.error
            stateField.errorText = field.errorText
          }
        })
      })
    },
    validateFieldsP(state, fields) {
      fields.map(function(field) {
        state.plainform.fields.map(function(stateField) {
          if (stateField.id === field.id) {
            stateField.error = field.error
            stateField.errorText = field.errorText
          }
        })
      })
    },
    basketCountIncrement(state) {
      state.basketCount = 1 + state.basketCount
    },
    productsInBasket(state, productIds) {
      state.productsInBasket = state.productsInBasket.concat(productIds)
    },
    setProductPrices(state, prices) {
      state.productPrices = prices
    },
    setProductItems(state, productItems) {
      state.productItems = productItems
    },
    setProductsItemsPage(state, page) {
      state.productItemsPage = page
    },
    setProductInfo(state, productInfo) {
      state.productInfo = productInfo
    }
  },
  actions: {
    compareCount({commit, state}, count) {
      commit('compareCount', count)
    },
    loadCompareIds({commit, state}) {
      let response
      response = function(response) {
        commit('compareIds', response.data.data.compareIds)
      }

      shop.getCompareIds(response)
    },
    addToBasket ({commit, state}, product) {
      let response
      response = function(response) {
        console.log(response)
      }

      commit('productsInBasket', [product.id])
      commit('basketCountIncrement')
      shop.addToBasket(product.id, product.quantity, response)
    },
    addToCompare ({commit, state}, productId) {
      let response;
      response = function(response) {
        console.log(response)
      }

      commit('compareIds', [productId])
      commit('compareCount', ++state.compareCount)
      shop.addToCompare(productId, response)
    },
    loadProductsInBasket ({commit, state}) {
      shop.loadProductsInBasket(
        response => {
          if (typeof response.data.data.productsIds === 'object') {
            commit('productsInBasket', response.data.data.productsIds)
          }
        }
      )
    },
    getProductPrices({commit, state}, productId) {
      let response;

      response = function(response) {
        commit('setProductPrices', response.data.data.prices)
      }

      shop.getProductPrices(productId).then(response)
    },
    getProductItems({commit, state}, filter) {
      shop.getProductItems(filter).then(function(response) {
        commit('setProductItems', response.data.data.productItems)

        let startedProductList
        startedProductList = document.querySelectorAll('.products__started')
        if (startedProductList[0].style.display != 'none') {
          startedProductList[0].style.display = 'none'
          startedProductList[1].style.display = 'none'
        }
      })
    },
    getProductInfo({commit, state}, productId) {
      if (productId) {
        shop.getProductInfo(productId).then(function (response) {
          commit('setProductInfo', response.data.data.productInfo)
        })
      }
    },
    setProductsItemsPage({commit, state}, page) {
      commit('setProductsItemsPage', page);
    },
    setProductsInfo({commit, state}, productInfo) {
      commit('setProductsInfo', productInfo);
    }
  }
});

export default store;
