var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "product-page__info-amount clr-blue-10 js-product-amount t-xxs",
    },
    [
      _c(
        "button",
        {
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.$emit("decrement")
            },
          },
        },
        [_vm._v("Удалить 1 товар")]
      ),
      _c(
        "input",
        _vm._g(
          {
            attrs: {
              type: "number",
              min: "1",
              max: "1000",
              size: "3",
              step: "1",
            },
            domProps: { value: _vm.value },
          },
          _vm.listeners
        )
      ),
      _c(
        "button",
        {
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.$emit("increment")
            },
          },
        },
        [_vm._v("Добавить 1 товар")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }