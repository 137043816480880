
// SCSS
import '~/assets/scss/main-landpage.scss'

// JS
import '~/js/helpers/polyfills'
import '~/js/helpers/css-props'
import '~/js/modules/simple-dropdown'
import '~/js/modules/scrollto'

import '~/js/modules/simple-dropdown'
import getSwiperSlider from '~/js/modules/slider'
import ImageFullscreen from '~/js/modules/image-fullscreen'
import Amount from '~/js/modules/amount'
import '../../pug/blocks/product-page-controls/product-page-controls'
import '../../pug/blocks/product-landpage/product-landpage'

const AMOUNT_IDS = []

const sliderImageClickHandler = (e) => new ImageFullscreen(e.currentTarget)


if (!Object.assign) {
  Object.defineProperty(Object, 'assign', {
    enumerable: false,
    configurable: true,
    writable: true,
    value: function (target, firstSource) {
      'use strict';
      if (target === undefined || target === null) {
        throw new TypeError('Cannot convert first argument to object');
      }

      var to = Object(target);
      for (var i = 1; i < arguments.length; i++) {
        var nextSource = arguments[i];
        if (nextSource === undefined || nextSource === null) {
          continue;
        }

        var keysArray = Object.keys(Object(nextSource));
        for (var nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex++) {
          var nextKey = keysArray[nextIndex];
          var desc = Object.getOwnPropertyDescriptor(nextSource, nextKey);
          if (desc !== undefined && desc.enumerable) {
            to[nextKey] = nextSource[nextKey];
          }
        }
      }
      return to;
    }
  });
}

document.addEventListener(`DOMContentLoaded`, () => {
  const productMainSlider = document.querySelector(`.js-product-slider-main`)
  const productThumbsSlider = document.querySelector(`.js-product-slider-thumbs`)
  const imagesInSider = document.querySelector(`.product-page__info-thumb img`)



  let productThumbsSwiper
  let productMainSwiper

  const productMainSwiperParams = {
    effect: `fade`,
    allowTouchMove: false,
    simulateTouch: false,
    on: {
      init() {
        const images = this.el.querySelectorAll(`.js-product-info-img`)

        if (images.length) {
          images.forEach(image => {
            image.addEventListener(`click`, sliderImageClickHandler)
          })
        }
      }
    }
  }

  if (productMainSlider) {
    productMainSwiper = getSwiperSlider(productMainSlider, productMainSwiperParams)
  }

  const productThumbsSwiperParams = {
    slidesPerView: `auto`,
    allowTouchMove: false,
    centerInsufficientSlides: true,
    speed: 0,
    navigation: {
      nextEl: `.swiper-button-next`,
      prevEl: `.swiper-button-prev`,
    },
    thumbs: {
      swiper: productMainSwiper
    },
    on: {
      init() {
        this.update()

        const images = this.el.querySelectorAll(`.product-page__info-slide`)

        if (images.length) {
          images.forEach(image => {
            image.addEventListener(`click`, function () {
              productThumbsSwiper.slideTo(this.dataset.index, 60);
            })
          })
        }

        if (this.slides.length > 3) {
          if (!this.params.loop) {
            setTimeout(() => {
              productMainSwiper.destroy()
              productMainSwiper = getSwiperSlider(productMainSlider, Object.assign({}, productMainSwiperParams, {
                loop: true,
              }))

              this.destroy()
              productThumbsSwiper = getSwiperSlider(productThumbsSlider, Object.assign({}, productThumbsSwiperParams, {
                loop: true,
                slidesPerView: 3,
                centerInsufficientSlides: false,
                centeredSlides: true,
                thumbs: {
                  swiper: productMainSwiper
                }
              }))
            })
          }
        } else {
          setTimeout(() => {
            if (this.navigation.nextEl) this.navigation.nextEl.remove()
            if (this.navigation.prevEl) this.navigation.prevEl.remove()
          })
        }
      }
    }
  }

  if (productThumbsSlider) {
    productThumbsSwiper = getSwiperSlider(productThumbsSlider, productThumbsSwiperParams)
  }

  const amountInstances = []

  if (AMOUNT_IDS.length) {
    AMOUNT_IDS.forEach(id => amountInstances.push(new Amount(id)))
  }

  document.addEventListener(`onAmountChanged`, (e) => {
    const { value } = e.detail

    if (value) {
      const table = document.querySelector(`.js-table`)

      if (table) {
        const rows = table.querySelectorAll(`.js-table-row`)

        if (rows.length) {
          const activeRow = [...rows].find(row => row.classList.contains(`active`))

          rows.forEach(row => {
            const min = +row.dataset.min
            const max = +row.dataset.max

            if (value >= min && value <= max) {
              if (row !== activeRow) {
                activeRow.classList.remove(`active`)
                row.classList.add(`active`)
              }
            }
          })
        }
      }
    }
  })

  document.addEventListener(`onTabsChanged`, (e) => {
    const { value } = e.detail

    if (value) {
      const currentTab = document.querySelector(`.js-tab[data-value=${value}]`)

      if (currentTab) {
        const activeTab = currentTab.parentElement.querySelector(`.js-tab.active`)

        if (activeTab) {
          activeTab.classList.remove(`active`)
        }

        currentTab.classList.add(`active`)

        if ((value === `similar` || value === `accessories`) && currentTab.querySelector(`.js-product-slider-similar`)) {
          productSimilarSlider = currentTab.querySelector(`.js-product-slider-similar`)
          productSimilarSwiper.destroy()
          productSimilarSwiper = getSwiperSlider(productSimilarSlider, productSimilarSwiperParams)
        }
      }
    }
  })

  let productSimilarSlider = document.querySelector(`.js-product-slider-similar`)

  let productSimilarSwiper

  const productSimilarSwiperParams = {
    slidesPerView: `auto`,
    loop: false,
    autoplay: false,
    pagination: false,
    on: {
      init() {
        const title = document.querySelector(`.js-product-similar-title`)
        const sliderArrows = document.querySelector(`.js-product-similar-arrows`)

        if (title) {
          title.style.display = `none`
        }

        if (sliderArrows) {
          sliderArrows.style.display = `none`
        }

        setTimeout(() => {
          if ((!productSimilarSwiper.isBeginning || !productSimilarSwiper.isEnd) && !productSimilarSwiper.params.loop) {
            productSimilarSwiper.destroy(true, true)

            const updatedParams = Object.assign({}, productSimilarSwiperParams, {
              loop: true
            })

            productSimilarSwiper = getSwiperSlider(productSimilarSlider, updatedParams)

            if (title) {
              title.style.display = ``
            }

            if (sliderArrows) {
              sliderArrows.style.display = ``
            }
          }
        })

        const currentPage = document.querySelector(`.js-product-similar-arrows .slider-arrows__page--current`)
        const totalPage = document.querySelector(`.js-product-similar-arrows .slider-arrows__page--total`)

        if (currentPage) {
          const currentIndex = this.realIndex < 10
            ? `0${this.realIndex + 1}`
            : `${this.realIndex + 1}`
          currentPage.textContent = currentIndex
        }

        if (totalPage) {
          const totalSlides = this.loopedSlides < 10
            ? `0${this.loopedSlides}`
            : `${this.loopedSlides}`
          totalPage.textContent = totalSlides
        }
      },
      resize() {
        setTimeout(() => {
          productSimilarSwiper.destroy(true, true)
          productSimilarSwiper = getSwiperSlider(productSimilarSlider, productSimilarSwiperParams)
        })
      },
      slideChange() {
        const currentPage = document.querySelector(`.js-product-similar-arrows .slider-arrows__page--current`)

        if (currentPage) {
          const currentIndex = this.realIndex < 10
            ? `0${this.realIndex + 1}`
            : `${this.realIndex + 1}`
          currentPage.textContent = currentIndex
        }
      }
    }
  }
  if (productSimilarSlider) {
    productSimilarSwiper = getSwiperSlider(productSimilarSlider, productSimilarSwiperParams)
  }
})
