const simpleDropdowns = document.querySelectorAll(".js-dropdown");

if (simpleDropdowns) {
    simpleDropdowns.forEach((el) => {
      let dropDownJs;

      dropDownJs = el.querySelector(".js-dropdown__btn");
      if (dropDownJs) {
        dropDownJs.addEventListener("click", () => {
          el.classList.toggle("is-active")
        })
      }
    })
}
