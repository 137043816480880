export default class Amount {
  constructor(id) {
    this.id = id
    this.el = document.querySelector(`.js-${this.id}-amount`)
    this.field = document.querySelector(`.js-${this.id}-amount-field`)
    this.btnRemove = document.querySelector(`.js-${this.id}-amount-remove`)
    this.btnAdd = document.querySelector(`.js-${this.id}-amount-add`)

    this.amount = 1

    this.fieldChangeHandler = this.fieldChangeHandler.bind(this)
    this.btnClickHandler = this.btnClickHandler.bind(this)

    this.init()
  }

  fieldChangeHandler() {
    if (+this.field.value < +this.field.min) this.field.value = +this.field.min
    else if (+this.field.value > +this.field.max) this.field.value = +this.field.max

    this.emit(+this.field.value)
  }

  btnClickHandler(e) {
    if (e.currentTarget === this.btnRemove) {
      if (+this.field.value > +this.field.min) {
        +this.field.value--
        this.emit(+this.field.value)
      }
    } else if (e.currentTarget === this.btnAdd) {
      if (+this.field.value < +this.field.max) {
        +this.field.value++
        this.emit(+this.field.value)
      }
    }
  }

  emit(value) {
    const event = new CustomEvent(`onAmountChanged`, {
      detail: { value }
    })
    document.dispatchEvent(event)
  }

  bind() {
    this.field.addEventListener(`change`, this.fieldChangeHandler)
    this.btnRemove.addEventListener(`click`, this.btnClickHandler)
    this.btnAdd.addEventListener(`click`, this.btnClickHandler)
  }

  init() {
    this.bind()

    this.field.value = this.amount
  }
}
