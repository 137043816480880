<template lang="pug">
include ../../utils/icon-btn.pug

.controls
  productPrices(
    :productPrices="productPrices"
  )
  .product-page__info-btns
    counter(
      v-on:increment="increment"
      v-on:decrement="decrement"
      :value="count"
    )

    button.product-page__info-btn.btn.bg-blue-10.clr-white(
      v-if="!inBasket"
      type=`button`
      @click="addToBasket"
      ) Добавить к заказу
    a.product-page__info-btn.btn.bg-blue-10.clr-white(
      href="/cart/"
      v-if="inBasket"
      type=`button`
      ) Перейти в корзину


  .product-page__info-btns(style="margin-top: 15px;")
    button.product-page__info-btn.btn.bg-blue-10.clr-white(
      type=`button`
      v-if="!inCompare"
      @click="addToCompare"
      ) Добавить к сравнению
    a.product-page__info-btn.btn.bg-blue-10.clr-white(
      href='/compare/'
      type=`button`
      v-if="inCompare"
      ) В сравнении

  .product-page__info-btns(style="margin-top: 15px;")
    button.product-page__info-btn.btn.btn--outline.bg-white.clr-blue-10.js-modal-btn(
      @click="getCommercial()"
      type=`button` data-form-id=`10`
      ) Получить коммерческое предложение
    button.product-page__info-btn.btn.btn--outline.bg-white.clr-blue-10.js-modal-btn(
      @click="getQuestion()"
      type=`button` data-form-id=`8`
      ) Задать вопрос
</template>


<script>
  import store from '~/js/store'
  import counter from '~/pug/blocks/controls/counter.vue'
  import productPrices from './prices.vue'

  let productcontols = {
    data () {
      return {
        count: 1
      }
    },
    props: {
      productId: {
      },
    },
    components: {
      counter, productPrices
    },
    created: function() {
      store.dispatch('getProductPrices', this.productId)
    },
    computed: {
      inBasket() {
        let productsIds
        productsIds = store.getters.getProductInBasket(this.productId)
        return productsIds.length > 0  ? true: false
      },
      productPrices() {
        let prices
        let isActive
        prices = store.getters.getProductPrices

        isActive = function(price, count) {
            price['active'] = false;
            price['currency'] = price.CURRENCY  == 'USD' ? '$' : 'руб.'

            if (count >= price.QUANTITY_FROM && !price.QUANTITY_TO) {
              price['active'] = true;
            } else if (count >= price.QUANTITY_FROM && count <= price.QUANTITY_TO) {
              price['active'] = true;
              } else if (count <= price.QUANTITY_TO && !price.QUANTITY_FROM) {
              price['active'] = true;
            }
          return price
        }

        if (prices.length) {
          let count;
          count = this.count
          return prices.map(price => {
            price = isActive(price, count)
            return price
          })
        }
        return ''
      },
      inCompare() {
        let isCompare
        isCompare = store.getters.getCompareIds().filter(productId => productId === parseInt(this.productId))

        return isCompare.length > 0 ? true : false
      }
    },
    methods: {
      addToCompare() {
        store.dispatch('addToCompare', parseInt(this.productId))
      },
      addToBasket() {
        store.dispatch('addToBasket', { id: this.productId, quantity: this.count })
      },
      increment() {
        this.count++
      },
      decrement() {
        this.count--
      },
      getCommercial() {
        let event = new CustomEvent('showModal', {
          detail: {
            formId: 10
          }
        })
        document.dispatchEvent(event)
      },
      getQuestion() {
        let event = new CustomEvent('showModal', {
          detail: {
            formId: 8
          }
        })
        document.dispatchEvent(event)
      }
    }
  }
  export default productcontols
</script>
