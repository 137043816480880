import getScrollbarWidth from './get-scroll-width'

const setCssProperties = () => {
  document.body.classList.add(`resized`)

  // Scrollbar width
  const scrollbarWidth = getScrollbarWidth()
  document.documentElement.style.setProperty(
    `--scrollbar-width`,
    `${scrollbarWidth}px`
  )

  // Viewport height
  const vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty(
    `--vh`,
    `${vh}px`
  )

  // Header height
  const header = document.querySelector(`.js-header`)

  if (header) {
    if (!header.classList.contains(`opened`)) {
      const headerHeight = header.getBoundingClientRect().height
      document.documentElement.style.setProperty(
        `--header-height`,
        `${headerHeight}px`
      )
    }
  }

  // Bottom nav height
  const bottomNav = document.querySelector(`.js-bottom-nav`)

  if (bottomNav) {
    const bottomNavHeight = bottomNav.getBoundingClientRect().height
    document.documentElement.style.setProperty(
      `--bottom-nav-height`,
      `${bottomNavHeight}px`
    )
  }

  // Products height
  if (window.innerWidth < 1024) {
    const activeProductsItem = document.querySelector(`.js-products-item.active`)

    if (activeProductsItem) {
      const activeProductsSublist = activeProductsItem.querySelector(`.js-products-subitems`)

      if (activeProductsSublist) {
        const activeProductsSublistHeight = activeProductsSublist.scrollHeight

        document.documentElement.style.setProperty(
          `--active-products-sublist-height`,
          `${activeProductsSublistHeight}px`
        )
      }
    }
  } else {
    const productsList = document.querySelector(`.js-products-list`)

    if (productsList) {
      const productsListHeight = productsList.scrollHeight
      const activeProductsSublist = document.querySelector(`.js-products-sublist.active`)

      if (activeProductsSublist) {
        let activeProductsSublistHeight = activeProductsSublist.scrollHeight

        if (activeProductsSublistHeight < productsListHeight) {
          activeProductsSublistHeight = productsListHeight
        }

        document.documentElement.style.setProperty(
          `--active-products-sublist-height`,
          `${activeProductsSublistHeight}px`
        )
      }
    }
  }

  document.body.classList.remove(`resized`)
}

window.addEventListener(`DOMContentLoaded`, setCssProperties)
window.addEventListener(`resize`, setCssProperties)
